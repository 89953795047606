import React from 'react';
import logo from '../assets/images/logo_slogan.png';

export default function HeaderVideo() {

    return (
        <div className="header-video" data-aos="fade-up">
            <div className="embed-container no-radius">
                <video autoPlay muted loop>
                    <source src="https://d309qpknnqn0vi.cloudfront.net/video_home_brukfit.mp4" type="video/mp4" />
                </video>
            </div>
            <img src={logo} />
        </div>
    );
}