import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FaInstagram, FaFacebookF, FaLinkedinIn, FaYoutube, FaChevronDown } from "react-icons/fa";

import logo from '../assets/images/icon.png';
import logo_btn from '../assets/images/logo_black.png';
import br from '../assets/images/br.png';
import uk from '../assets/images/uk.png';

export default function Header() {

    return (
        <div className="header-container">
            <div className="container-fluid">
                <div className="header-content">
                    <div className="header-social">
                        <Link to="/" className="menu-logo">
                            <img src={logo} />
                        </Link>
                        <a href="https://www.instagram.com/brukfit/" target="_blank" className="menu-icon">
                            <FaInstagram />
                        </a>
                        <a href="https://www.facebook.com/brukfit" target="_blank" className="menu-icon">
                            <FaFacebookF />
                        </a>
                        <a href="https://www.linkedin.com/company/brukfit" target="_blank" className="menu-icon">
                            <FaLinkedinIn />
                        </a>
                        <a href="https://www.youtube.com/@brukfit" target="_blank" className="menu-icon">
                            <FaYoutube />
                        </a>
                    </div>
                    <div className="header-container-menu">
                        <div className="menu-language-digital">
                            <div className="menu-language">
                                <div className="menu-language-logo"><img src={br} /></div>
                                <div className="menu-language-logo"><img src={uk} /></div>
                            </div>
                            <a href="https://gym.bruk.digital" target="_blank" className="menu-digital">
                                <div className="menu-digital-logo"><img src={logo_btn} /></div>
                                <div className="menu-digital-text">Digital</div>
                            </a>
                        </div>
                        <div className="header-menu">
                            <NavLink to="/" className="menu-text" activeClassName="active">
                                <div className="menu-item-text">Home</div>
                            </NavLink>
                            <NavLink to="/quem-somos" className="menu-text" activeClassName="active">
                                <div className="menu-item-text">Quem Somos</div>
                            </NavLink>
                            <div className="menu-text" activeClassName="active">
                                <div className="menu-item-text">Unidades</div>
                                <div className="menu-item-icon"><FaChevronDown /></div>
                                <div className="menu-ul">
                                    <a href="https://ipanema.bruk.digital/" target="_blank">BRUK Ipanema</a>
                                    <a href="https://konnen.bruk.digital/" target="_blank">BRUK Konnen</a>
                                    <a href="javascript:;" target="_blank">BRUK Konnen VP</a>
                                    <a href="javascript:;" target="_blank">BRUK Barra</a>
                                    <a href="javascript:;" target="_blank">BRUK Petrópolis</a>
                                    <a href="javascript:;" target="_blank">BRUK Valle</a>
                                    <a href="javascript:;" target="_blank">BRUK Itaipava</a>
                                    <a href="javascript:;" target="_blank">BRUK Praia</a>
                                    <a href="javascript:;" target="_blank">BRUK Bauru</a>
                                </div>
                            </div>
                            <NavLink to="/franquia" className="menu-text" activeClassName="active">
                                <div className="menu-item-text">Franquia</div>
                            </NavLink>
                            <NavLink to="/novidades" className="menu-text" activeClassName="active">
                                <div className="menu-item-text">News</div>
                            </NavLink>
                            <NavLink to="/contato" className="menu-text" activeClassName="active">
                                <div className="menu-item-text">Contato</div>
                            </NavLink>
                            <a href="https://reserva.ink/bruk.fit" target="_blank" className="menu-text">
                                <div className="menu-item-text">Camisas</div>
                            </a>
                            <NavLink to="/loja" className="menu-text" activeClassName="active">
                                <div className="menu-item-text">Loja</div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}