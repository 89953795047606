import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaInstagram, FaFacebookF, FaLinkedinIn, FaYoutube, FaChevronDown } from "react-icons/fa";

import banner from '../assets/images/bg-footer.png';
import logo from '../assets/images/logo.png';
import logo_nerdetcetera from '../assets/images/logo_nerdetcetera.png';

export default function Footer() {

    const navigate = useNavigate();

    return (
        <div className="footer-container" data-aos="fade-up" data-aos-delay="50">
            <div className="footer-all">
                <div className="footer-newsletter">
                    <div className="footer-newsletter-text">
                        Seja o primeiro<br />
                        a saber as novidades<br />
                        BRUK.
                    </div>
                    <div className="footer-newsletter-form">
                        <input type="text" />
                        <a href="javascript:;">Cadastrar</a>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="footer-logo-container">
                        <Link to="/" className="menu-logo">
                            <img src={logo} />
                        </Link>
                        <div className="footer-logo">
                            <a href="https://www.instagram.com/brukfit/" target="_blank" className="menu-icon">
                                <FaInstagram />
                            </a>
                            <a href="https://www.facebook.com/brukfit" target="_blank" className="menu-icon">
                                <FaFacebookF />
                            </a>
                            <a href="https://www.linkedin.com/company/brukfit" target="_blank" className="menu-icon">
                                <FaLinkedinIn />
                            </a>
                            <a href="https://www.youtube.com/@brukfit" target="_blank" className="menu-icon">
                                <FaYoutube />
                            </a>
                        </div>
                    </div>
                    <div className="footer-menu">
                        <div>
                            <Link to="/">HOME</Link>
                            <Link to="/quem-somos">QUEM SOMOS</Link>
                        </div>
                        <div>
                            <Link to="/franquia">FRANQUIA</Link>
                            <Link to="/novidades">NEWS</Link>
                        </div>
                        <div>
                            <Link to="/pagina/politica-de-privacidade">POLÍTICA DE PRIVACIDADE</Link>
                            <Link to="/contato">CONTATO</Link>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright">
                    <div>©2024 BRUK FIT - Todos os direitos reservados.</div>
                    <div className="logo-nerdetcetera">
                        <div>Desenvolvido por</div>
                        <a href="https://nerdetcetera.com" target="_blank">
                            <img src={logo_nerdetcetera} />
                        </a>
                    </div>
                </div>
            </div>
            <img src={banner} />
        </div>
    );
}