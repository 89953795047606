import React from 'react';

import banner from '../assets/images/banner-gus.jpg';

export default function HeaderGus() {

    return (
        <div className="header-gus" data-aos="fade-up" data-aos-delay="150">
            <a href="https://www.instagram.com/gus.vaztostes/" target="_blank">
                <img src={banner} />
            </a>
        </div>
    );
}