import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdAutoGraph, MdOutlineSmartToy, MdFitnessCenter, MdOutlineRealEstateAgent, MdOutlineHandshake, MdOutlineSpeakerNotes, MdShareLocation } from "react-icons/md";
import InputMask from "react-input-mask";

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Shimmer from '../../components/Shimmer';

import banner from '../../assets/images/banner.jpg';
import bruk_1 from '../../assets/images/bruk-1.png';
import bruk_2 from '../../assets/images/bruk-2.png';
import shape_top from '../../assets/images/shape-top.png';
import shape_bottom from '../../assets/images/shape-bottom.png';

import api from '../../services/api';

export default function Franquia() {
    
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [city, setCity] = useState('');
    const [message, setMessage] = useState('');

    const [loading, setLoading] = useState(false);

    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);

    async function submitContact(e){
        e.preventDefault();
        setLoading(true);
        try{
            if(name==="" || email==="" || phone==="" || city==="" || message===""){
                setLoading(false);
                setError(true);
                setErrorMessage('Preencha todos os campos!');
                return;
            }

            const response = await api.post('/contact-franquia', {
                name,
                email,
                phone,
                city,
                message,
            });

            setError(false);
            setLoading(false);
            setSuccess(true);
            setName('');
            setEmail('');
            setPhone('');
            setCity('');
            setMessage('');

        }catch(err){
            setLoading(false);
            setError(true);
            setErrorMessage('Erro ao enviar informações');
        }
    }

    return (
        <div className="page-wrapper">
            <Header />
            <div className="content-wrapper page-content">
                <h4 className="fw-semibold" data-aos="fade-up" data-aos-delay="50">Franquia</h4>
                <div className="row pt-4 pb-4">
                    <div className="content-section section-1">
                        <h2 data-aos="fade-up" data-aos-delay="100">Por que se tornar um Franqueado BRUK?</h2>
                        <div className="col-md-8 m-auto">
                            <div className="row">
                                <div className="col-md-6 m-auto content-box-why" data-aos="fade-up" data-aos-delay="150">
                                    <div>
                                        <MdAutoGraph />
                                        <p>Propósito de crescimento de sua equipe e da comunidade BRUK</p>
                                    </div>
                                </div>
                                <div className="col-md-6 m-auto content-box-why" data-aos="fade-up" data-aos-delay="200">
                                    <div>
                                        <MdOutlineSmartToy />
                                        <p>Tecnologia trabalhando para a melhor experiência de seus alunos</p>
                                    </div>
                                </div>
                                <div className="col-md-6 m-auto content-box-why" data-aos="fade-up" data-aos-delay="250">
                                    <div>
                                        <MdFitnessCenter />
                                        <p>Treinamento para toda a sua equipe, além de e-books complementares</p>
                                    </div>
                                </div>
                                <div className="col-md-6 m-auto content-box-why" data-aos="fade-up" data-aos-delay="300">
                                    <div>
                                        <MdOutlineRealEstateAgent />
                                        <p>Acompanhamos de perto seu box e a execução de seus projetos</p>
                                    </div>
                                </div>
                                <div className="col-md-6 m-auto content-box-why" data-aos="fade-up" data-aos-delay="350">
                                    <div>
                                        <MdOutlineSpeakerNotes />
                                        <p>Marketing com excelentes resultados</p>
                                    </div>
                                </div>
                                <div className="col-md-6 m-auto content-box-why" data-aos="fade-up" data-aos-delay="400">
                                    <div>
                                        <MdShareLocation />
                                        <p>Presença no mercado internacional</p>
                                    </div>
                                </div>
                                <div className="col-md-8 m-auto content-box-why" data-aos="fade-up" data-aos-delay="450">
                                    <div>
                                        <MdOutlineHandshake />
                                        <p>Consultoria com profissionais renomados pelo mercado disponíveis para o seu crescimento</p>
                                    </div>
                                </div>
                                <div className="col-md-12 text-center mt-5" data-aos="fade-up" data-aos-delay="500">
                                    <a href="#franquia" className="btn-border-mini">Torne-se um Franqueado</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-section section-2" data-aos="fade-up" data-aos-delay="50">

                        <div className="content-shape-top">
                            <img src={shape_top} />
                        </div>

                        <div className="row">
                            <div className="col-md-6 m-auto pt-4 pb-4">
                                <h2>Nosso time é o melhor</h2>
                                <p>Com <a href="https://www.instagram.com/tito.atc/" target="_blank" rel="noreferrer">Alexandre Tito</a> e <a href="https://www.instagram.com/fernandoabreusopa/" target="_blank" rel="noreferrer">Fernando Sopa</a>, especialistas em Gestão Empresarial, <a href="https://www.instagram.com/gus.witfitness/" target="_blank" rel="noreferrer">Coach Gus</a>, gestor de academias e especialista no esporte, e <a href="https://www.instagram.com/lapateam/" target="_blank" rel="noreferrer">Ricardo Lapa</a>, um dos maiores empresários do ramo fitness no Brasil.</p>
                            </div>
                        </div>

                        <div className="content-shape-bottom">
                            <img src={shape_bottom} />
                        </div>

                    </div>

                    <div className="content-section section-3">
                        <div className="col-md-6 m-auto">
                            <h2 data-aos="fade-up" data-aos-delay="50">Contamos com uma gestão inteligente, suporte, treinamento e um método de treino 100% voltado a entrega de resultados. Venha conferir.</h2>
                            
                        </div>
                        <div className="col-md-12 text-center mt-5" data-aos="fade-up" data-aos-delay="150">
                            <a href="#franquia" className="btn-border-mini">Torne-se um Franqueado</a>
                        </div>
                    </div>



                    <div className="content-section section-4">
                        <div className="content-shape-top">
                            <img src={shape_top} />
                        </div>
                        <div className="row">
                            <div className="col-md-6 m-auto" data-aos="fade-up" data-aos-delay="50">
                                <img src={bruk_1} />
                            </div>
                            <div className="col-md-6 m-auto" data-aos="fade-up" data-aos-delay="100">
                                <h2>Segmento promissor</h2>
                                <p>Você sabia que o mercado fitness do Brasil movimenta mais de 8 bilhões de reais por ano? E que somente 4% da população nacional está matriculada em algum tipo de academia para a prática recorrente de atividade física? Fique atento as oportunidades.</p>

                                <h2 className="pt-4">Nossa marca</h2>
                                <p>Na BRUK, tudo é cuidadosamente pensado a fim de reforçar o senso de comunidade de nossa rede e unidades. Nossos alunos e clientes são o maior patrimônio da nossa empresa.</p>
                            
                            </div>
                        </div>
                    </div>

                    <div className="content-section section-5" data-aos="fade-up" data-aos-delay="50">
                        <div className="row row-first">
                            <div className="col-md-6 m-auto">
                                <h2>Invista na sua comunidade</h2>
                                <p>Queremos você comprometido em mudar a realidade do sedentarismo mundial entregando saúde e qualidade de vida a milhares de pessoas junto conosco.</p>
                                <p>Se você tem interesse em investir na marca mais forte do mercado e se juntar a este mega projeto, faça agora um contato conosco.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content-section section-6" id="franquia">
                    <h2 data-aos="fade-up" data-aos-delay="50">Quer ser um franqueado?</h2>
                    <div className="row pt-4 pb-4">
                        <div className="col-md-6 m-auto" data-aos="fade-up" data-aos-delay="150">
                            <p data-aos="fade-up" data-aos-delay="100">Entre em contato com uma mensagem pelo Whatsapp<br />ou preencha o formulário abaixo, é bem rápido!</p>
                            <div className="row gy-3">
                                <div className="col-xl-10 m-auto">
                                    <label className="form-label">Nome:</label>
                                    <input type="text" className="form-control form-control-light" value={name} onChange={e => setName(e.target.value)} />
                                </div>
                                <div className="col-xl-10 m-auto">
                                    <label className="form-label mt-4">E-mail:</label>
                                    <input type="email" className="form-control form-control-light" value={email} onChange={e => setEmail(e.target.value)} />
                                </div>
                                <div className="col-xl-10 m-auto">
                                    <label className="form-label mt-4">Telefone:</label>
                                    <InputMask type="tel" className="form-control form-control-light" mask="(99) 99999-9999" value={phone} onChange={e => setPhone(e.target.value)} />
                                </div>
                                <div className="col-xl-10 m-auto">
                                    <label className="form-label mt-4">Em qual cidade gostaria de abrir sua BRUK?:</label>
                                    <input type="text" className="form-control form-control-light" value={city} onChange={e => setCity(e.target.value)} />
                                </div>
                                <div className="col-xl-10 m-auto">
                                    <label className="form-label mt-4">Nos conte um pouco sobre você:</label>
                                    <textarea className="form-control form-control-light" rows="3" value={message} onChange={e => setMessage(e.target.value)}></textarea>
                                </div>
                                <div className="col-xl-10 m-auto mt-4">
                                    <div className="text-end">
                                        <button className="btn btn-primary-light btn-wave waves-effect waves-light" onClick={submitContact}>Enviar</button>
                                    </div>
                                </div>
                                {error &&
                                <div className="col-xl-10 m-auto mt-4">
                                    <div className="alert alert-danger">
                                        {error_message}
                                    </div>
                                </div>}
                                {success &&
                                <div className="col-xl-10 m-auto mt-4">
                                    <div className="alert alert-success">
                                        Informações enviadas com sucesso!
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
