import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../../components/Header';
import HeaderVideo from '../../components/HeaderVideo';
import HeaderMetodologia from '../../components/HeaderMetodologia';
import HeaderApp from '../../components/HeaderApp';
import HeaderGus from '../../components/HeaderGus';
import ContentVideo from '../../components/ContentVideo';
import ContentNews from '../../components/ContentNews';
import ContentGym from '../../components/ContentGym';
import ContentBranch from '../../components/ContentBranch';

import Footer from '../../components/Footer';
import Content from '../../components/Content';
import Shimmer from '../../components/Shimmer';

import api from '../../services/api';

export default function Home() {
    
    const [loading, setLoading] = useState(true);

    async function loadPage(){
        /* const response = await api.post(`/`, {}, config);
        console.log(response.data, token);
        setLoading(false); */
    }

    useEffect(() => {
        
        loadPage();
    }, []);

    return (
        <div className="page-wrapper">
            <Header />
            <HeaderVideo />
            <HeaderMetodologia />
            <HeaderApp />
            <HeaderGus />
            <ContentVideo />
            <ContentNews />
            <ContentGym />
            <ContentBranch />
            
            <Footer />
        </div>
    );
}
