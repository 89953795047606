import React, { useState, useContext, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import InputMask from "react-input-mask";

import Header from '../../components/Header';
import HeaderMetodologia from '../../components/HeaderMetodologia';
import Footer from '../../components/Footer';
import Podcast from '../../components/Podcast';
import ImageText from '../../components/ImageText';
import Videos from '../../components/Videos';
import Gallery from '../../components/Gallery';

import api from '../../services/api';

import base from '../../assets/images/blank-square.png';

export default function Page() {

    const { url } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [loading_page, setLoadingPage] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [text, setText] = useState('');

    const [page, setPage] = useState([]);
    const [page_items, setPageItems] = useState([]);

    const [error, setError] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);

    async function submitContact(e){
        e.preventDefault();
        setLoadingPage(true);
        try{
            if(name==="" || email==="" || phone===""){
                setLoadingPage(false);
                setError(true);
                setErrorMessage('Preencha todos os campos!');
                return;
            }

            await api.post('/leads', {
                id: page.id,
                name,
                email,
                phone,
                text,
            });

            setError(false);
            setLoadingPage(false);
            setSuccess(true);
            setName('');
            setEmail('');
            setPhone('');
            setText('');

        }catch(err){
            setLoadingPage(false);
            setError(true);
            setErrorMessage('Erro ao enviar informações');
        }
    }

    async function loadPage(){
         setLoading(true);
        try{
            const response = await api.post(`/pages-site/${url}`);
            setPage(response.data.data.pages);
            setPageItems(response.data.data.pages.pages_items ? response.data.data.pages.pages_items : []);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    useEffect(() => {
        if(url){
            loadPage();
        }else{
            navigate('/');
        }
    }, [url]);

    return (
        <div className="page-wrapper">
            <Header />
            {!loading &&
            <>
                <div className="content-wrapper page-content">
                    <h4 className="fw-semibold" data-aos="fade-up" data-aos-delay="50">{page.title}</h4>
                </div>
                <div className="content-wrapper page-content">
                    {page_items!='' &&
                    <div className="post-content">
                        {page_items.map((item, index) => (
                        <div className="content-container" key={item.id} data-aos="fade-up" data-aos-delay={`${(index + 1) * 50}`} style={{backgroundColor: item.page_bg, paddingTop: Number(item.page_top), paddingBottom: Number(item.page_bottom) }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-10 m-auto">
                                        <div className="fs-15">
                                            {item.type==="text" &&
                                            <div dangerouslySetInnerHTML={{__html: item.text}} />}
                                            {item.type==="podcast" &&
                                            <Podcast type={item.podcast_type} id={item.podcast} />}
                                        
                                            {item.type==="imagetext" &&
                                            <ImageText type={item.image_type} image={item.image} text={item.text} />}
                                            {item.type==="video" &&
                                            <Videos videos={item.videos} />}
                                            {item.type==="image" &&
                                            <Gallery images={item.gallery} />}
                                            {item.type==="button" &&
                                            <a href={item.link} target="_blank" className="btn btn-custom" style={{color: item.link_color, backgroundColor: item.link_bg}}>
                                                {item.link_text}
                                            </a>}
                                            {item.type==="leads" &&
                                            <div className="row">
                                                <div className="col-md-6 m-auto" data-aos="fade-up" data-aos-delay="150">
                                                    <div className="row gy-3">
                                                        <div className="col-xl-10 m-auto">
                                                            <label className="form-label">Nome *</label>
                                                            <input type="text" className="form-control form-control-light" value={name} onChange={e => setName(e.target.value)} />
                                                        </div>
                                                        <div className="col-xl-10 m-auto">
                                                            <label className="form-label mt-4">E-mail *</label>
                                                            <input type="email" className="form-control form-control-light" value={email} onChange={e => setEmail(e.target.value)} />
                                                        </div>
                                                        <div className="col-xl-10 m-auto">
                                                            <label className="form-label mt-4">Telefone *</label>
                                                            <InputMask type="tel" className="form-control form-control-light" mask="(99) 99999-9999" value={phone} onChange={e => setPhone(e.target.value)} />
                                                        </div>
                                                        {item.title &&
                                                        <div className="col-xl-10 m-auto">
                                                            <label className="form-label mt-4">{item.title}</label>
                                                            <textarea className="form-control form-control-light" rows="6" value={text} onChange={e => setText(e.target.value)}></textarea>
                                                        </div>}
                                                        <div className="col-xl-10 m-auto mt-4">
                                                            <div className="text-end">
                                                                {loading_page ?
                                                                <button className="btn btn-primary btn-wave waves-effect waves-light" disabled>
                                                                    <span className="spinner-border spinner-border-sm align-middle me-2" role="status" aria-hidden="true"></span>
                                                                    <span>Enviando...</span>
                                                                </button>:
                                                                <button className="btn btn-primary btn-wave waves-effect waves-light" onClick={submitContact}>Enviar</button>}
                                                            </div>
                                                        </div>
                                                        {error &&
                                                        <div className="col-xl-10 m-auto mt-4">
                                                            <div className="alert alert-danger">
                                                                {error_message}
                                                            </div>
                                                        </div>}
                                                        {success &&
                                                        <div className="col-xl-10 m-auto mt-4">
                                                            <div className="alert alert-success">
                                                                Informações enviadas com sucesso!
                                                            </div>
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>))}
                    </div>}
                    <Footer />
                </div>
            </>}
        </div>
    );
}
