import React,  { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { FaPlay } from "react-icons/fa";
import { Carousel } from 'react-responsive-carousel';

import api from '../services/api';

import Base from '../assets/images/blank-video.png';

export default function ContentVideo() {

    const [loading, setLoading] = useState(true);
    const [video, setVideo] = useState('');
    const [id, setId] = useState('');
    const [videos, setVideos] = useState([]);
    const [playing, setPlaying] = useState(false);

    function playVideo(id, video){
        setId(id);
        setVideo(video);
        setPlaying(true)
    }

    async function loadPage(){
        try{
            const response = await api.post('/videos');
            const groupedVideos = chunkArray(response.data.data.videos, 3);
            setVideos(groupedVideos);
            if (response.data.data.videos && response.data.data.videos.length > 0) {
                setId(response.data.data.videos[0].id);
                setVideo(response.data.data.videos[0].video);
            }
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    function chunkArray(array, chunkSize) {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    }

    useEffect(() => {
        loadPage();
    }, []);

    return (
        <div className="content-videos">
            <h2 data-aos="fade-up" data-aos-delay="50">O que tá rolando</h2>
            <div className="container-video" data-aos="fade-up" data-aos-delay="100">
                <div className="embed-container">
                    <ReactPlayer
                        url={video}
                        controls={true}
                        playsinline={true}
                        playing={playing}
                        frameBorder="0"
                        allowFullScreen
                    />
                </div>
            </div>
            <div className="container-videos" data-aos="fade-up" data-aos-delay="150">
                <div className="container-fluid">
                    <div className="row">
                        <Carousel
                            className="carousel-videos"
                            interval={4000}
                            transitionTime={300}
                            autoPlay={false}
                            showArrows={true}
                            showIndicators={false}
                            showStatus={false}
                            showThumbs={false}
                            stopOnHover={false}
                            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                hasPrev && (
                                    <a onClick={onClickHandler} title={label} className="btn-prev">
                                        <FiChevronLeft />
                                    </a>
                                )
                            }
                            renderArrowNext={(onClickHandler, hasNext, label) =>
                                hasNext && (
                                    <a onClick={onClickHandler} title={label} className="btn-next">
                                        <FiChevronRight />
                                    </a>
                                )
                            }
                        >   
                            {videos.map((group, indexGroup) => (
                                <div class="col-md-12" key={indexGroup}>
                                    <div className="row">
                                    {group.map((item, index) => (
                                        <div class="col-md-4" key={index} data-aos="fade-up" data-aos-delay={`${(index + 1) * 50}`}>
                                            <a className={`video-thumb ${item.id==id ? 'current-video' : ''}`} onClick={() => playVideo(item.id, item.video)}>
                                                <div className="video-play">
                                                    <FaPlay />
                                                </div>
                                                <img src={Base} className="image-cover" style={{backgroundImage: `url(${item.image})`}} />
                                            </a>
                                        </div>
                                    ))}
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    );
}