import React,  { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import { Carousel } from 'react-responsive-carousel';

import api from '../services/api';

import Base from '../assets/images/blank-square.png';

export default function ContentNews() {

    const [loading, setLoading] = useState(true);
    const [id, setId] = useState('');
    const [posts, setPosts] = useState([]);

    async function loadPage(){
        try{
            const response = await api.post('/news');
            const groupedPosts = chunkArray(response.data.data.posts, 4);
            setPosts(groupedPosts);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    function chunkArray(array, chunkSize) {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    }

    useEffect(() => {
        loadPage();
    }, []);

    return (
        <div className="content-news">
            <h2 data-aos="fade-up" data-aos-delay="50">News</h2>
            <div className="container-news" data-aos="fade-up" data-aos-delay="100">
                <div className="container-fluid">
                    <div className="row">
                        <Carousel
                            className="carousel-news"
                            interval={4000}
                            transitionTime={300}
                            autoPlay={false}
                            showArrows={true}
                            showIndicators={false}
                            showStatus={false}
                            showThumbs={false}
                            stopOnHover={false}
                            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                hasPrev && (
                                    <a onClick={onClickHandler} title={label} className="btn-prev">
                                        <FiChevronLeft />
                                    </a>
                                )
                            }
                            renderArrowNext={(onClickHandler, hasNext, label) =>
                                hasNext && (
                                    <a onClick={onClickHandler} title={label} className="btn-next">
                                        <FiChevronRight />
                                    </a>
                                )
                            }
                        >   
                            {posts.map((group, indexGroup) => (
                                <div class="col-md-12" key={indexGroup}>
                                    <div className="row">
                                    {group.map((item, index) => (
                                        <div class="col-md-4" key={index} data-aos="fade-up" data-aos-delay={`${(index + 1) * 50}`}>
                                            <Link to={`/novidades/${item.url}`} className="news-thumb">
                                                <img src={Base} className="image-cover" style={{backgroundImage: `url(${item.image})`}} />
                                                <h4>{item.title}</h4>
                                                <p>{item.subtitle}</p>
                                            </Link>
                                        </div>
                                    ))}
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                        <div className="col-md-12 text-center mt-2 mb-5">
                            <a href="/novidades" className="btn-border">VER TODAS</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}