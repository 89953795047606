import React from 'react';
import { IoLogoApple, IoLogoAndroid } from "react-icons/io";

import app from '../assets/images/app.png';

export default function HeaderApp() {

    return (
        <div className="header-app">
            <div className="app-container">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-7" data-aos="fade-up" data-aos-delay="50">
                            <img src={app} className="app-img" />
                        </div>
                        <div className="col-md-5 app-container-text" data-aos="fade-up" data-aos-delay="150">
                            <h2>INOVAÇÃO</h2>
                            <p>Integrando o físico com o digital o APP BRUK trás conteúdos e funções exclusivas que vão do check-in até uma rede social exclusiva da comunidade BRUK.</p>
                            <p>
                                <span>App BRUK</span><br />
                                a extensão do seu treino!
                            </p>
                            <p>Lançamento EM BREVE!</p>
                            <div className="app-icons">
                                <IoLogoApple />
                                <IoLogoAndroid />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}