import React from 'react';

import bg from '../assets/images/bg-1.png';

export default function HeaderMetodologia({simple}) {

    return (
        <>
            <div className={`header-banner-text ${simple ? 'mb-5 pb-5' : ''}`}>
                <div className="banner-text-left" data-aos="fade-up" data-aos-delay="50">
                    <h2>Metodologia</h2>
                    <h4>INTERNACIONAL de Treino</h4>
                    <p>Com didática única e rara de se encontrar, atravessando as fronteiras e trazendo o que há de melhor do fitness mundial, a BRUK vem para mudar o conceito do que é participar de uma academia de verdade!</p>
                </div>
                <div className="banner-text-right" data-aos="fade-up" data-aos-delay="100">
                    <h2>Comunidade</h2>
                    <p>Dos diretores aos alunos, na BRUK todos estão comprometidos em promover inclusão.</p>
                    <p>Aqui é onde a mágica acontece… do iniciante ao mais bem condicionado, todos se beneficiam e evoluem com nosso método de treino.</p>
                </div>
            </div>
            {!simple &&
            <div className="header-banner negative" data-aos="fade-up" data-aos-delay="150">
                <img src={bg} />
            </div>}
        </>
    );
}