import React from 'react';
import { RiEyeFill, RiHeartsFill, RiAwardFill } from "react-icons/ri";

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Shimmer from '../../components/Shimmer';

import banner from '../../assets/images/banner.jpg';
import bruk_about from '../../assets/images/bruk_about.png';
import bruk_equipe from '../../assets/images/bruk_equipe.jpg';
import shape_top from '../../assets/images/shape-top.png';
import shape_bottom from '../../assets/images/shape-bottom.png';

export default function QuemSomos() {
    
    return (
        <div className="page-wrapper">
            <Header />
            <div className="content-wrapper page-content">
                <div className="row">
                    <div className="content-section section-7" data-aos="fade-up">
                        <div className="content-shape-top">
                            <img src={shape_top} />
                        </div>
                        <div className="row">
                            <div className="col-md-6 m-auto pb-4" data-aos="fade-up" data-aos-delay="50">
                                <h2 className="title-blk-green">O QUE NOS <span>MOVE?</span></h2>
                                <p>Seja bem-vindo à BRUK, muito mais do que uma academia, somos um centro de transformação e excelência em treinamento funcional de alta performance!</p>
                                <p>Nosso compromisso é claro e inspirador: trazer soluções e inovações constantes tanto para nossos alunos quanto para nossos investidores através de soluções inovadoras e o poder de uma comunidade unida.</p>
                                <p>Na BRUK, não nos apegamos a bandeiras de terceiros ou a métodos pré-estabelecidos. Nossa abordagem é simples: focamos no que funciona. Diariamente, trabalhamos para entregar resultados tangíveis aos nossos alunos e investidores. Sabemos que o verdadeiro sucesso se mede por transformações reais, e é isso que nos motiva a elevar constantemente nossos padrões.</p>
                            </div>
                        </div>

                        <div className="content-shape-bottom">
                            <img src={shape_bottom} />
                        </div>

                    </div>

                    <div className="content-section section-3 pt-0 pb-0">
                        <div className="col-md-12 m-auto" data-aos="fade-up" data-aos-delay="100">
                            <img src={bruk_about} />
                        </div>
                    </div>

                    <div className="content-section section-8">
                        <div className="content-shape-top">
                            <img src={shape_top} />
                        </div>
                        <div className="row">
                            <div className="col-md-6 m-auto pb-4" data-aos="fade-up" data-aos-delay="150">
                                <h2 className="title-blk-green">NOSSA <span>HISTÓRIA</span></h2>
                                <p>A BRUK surgiu da fusão entre excelência no treinamento e inovação na gestão do setor fitness.</p>
                                <p>Seus fundadores identificaram uma oportunidade ao perceber uma lacuna entre resultados de treinamento e administração eficiente. Com metodologia de treino exclusiva e gestão simplificada, a BRUK transforma pessoas e oferece soluções, simplificando a operação de suas unidades. A BRUK personifica a modernização do fitness, redefinindo padrões com a sinergia entre treinamento e gestão.</p>
                                <p>Nosso modelo de negócio atende todas às necessidades atuais do fitness, com métodos eficientes, sistemas personalizados, layout exclusivos e diversas formas de geração de receita. Junte-se a BRUK e faça parte também dessa revolução do fitness.</p>
                            </div>
                        </div>
                    </div>

                    <div className="content-section section-9">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-5 m-auto" data-aos="fade-up" data-aos-delay="200">
                                    <h2>
                                        <div className="about-icons"><RiHeartsFill /></div>
                                        <span>MISSÃO:</span>
                                    </h2>
                                    <p>Promover bem-estar através de métodos de treinamento eficazes e espaços únicos, valorizando vidas e construindo conexões significativas e duradouras.</p>
                                </div>
                                <div className="col-md-5 m-auto" data-aos="fade-up" data-aos-delay="250">
                                    <h2>
                                        <div className="about-icons"><RiEyeFill /></div>
                                        <span>VISÃO:</span>
                                    </h2>
                                    <p>Ser a melhor empresa fitness para investir, trabalhar, treinar e se divertir.</p>
                                </div>
                                <div className="col-md-5 m-auto" data-aos="fade-up" data-aos-delay="300">
                                    <h2>
                                        <div className="about-icons"><RiAwardFill /></div>
                                        <span>VALORES:</span>
                                    </h2>
                                    <p>Excelência: Nossa equipe de treinadores e funcionários é dedicada a oferecer experiências memoráveis aos nossos clientes, com compromisso inabalável com a qualidade.</p>
                                    <p>Compromisso com Resultados: Guiados por métodos de treinamento personalizados, estamos empenhados em proporcionar resultados tangíveis, direcionados às necessidades individuais de cada aluno.</p>
                                    <p>Igualdade: Celebramos a individualidade de todos, promovendo o conceito de pertencimento em todas as nossas unidades, cultivando um ambiente de respeito mútuo.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-section section-10">
                        <div className="col-md-8 m-auto">
                            <h2 className="title-blk-green" data-aos="fade-up" data-aos-delay="350">O TIME <span>BRUK</span></h2>
                            <p data-aos="fade-up" data-aos-delay="400">Alexandre Tito, Fernando Abreu, Gustavo Tostes e Ricardo Lapa – empreendedores experientes, criaram a empresa com objetivo de trazer soluções e inovações para o mercado fitness, tendo como meta surpreender e superar as expectativas do mercado e dos alunos/clientes <strong>BRUK</strong>.</p>
                        </div>
                        <div className="col-md-12 m-auto">
                            <img src={bruk_equipe} className="img-equipe pt-4" data-aos="fade-up" data-aos-delay="450" />
                        </div>
                        <div className="content-shape-bottom bg-black-shape">
                            <img src={shape_bottom} />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
