import React from 'react';
import { Link } from 'react-router-dom';

import shape_top from '../assets/images/shape-top-invert.png';
import shape_bottom from '../assets/images/shape-bottom-invert.png';

export default function ContentGym() {

    return (
        <div className="content-gym" data-aos="fade-up" data-aos-delay="50">
            <div className="gym-container-text">
                <div className="content-shape-top">
                    <img src={shape_top} />
                </div>
                <div className="container gym-space">
                    <div className="row">
                        <div className="col-md-8 m-auto">
                            <h2>Descubra o mundo de possibilidades que uma BRUK tem para oferecer!</h2>
                            <p>Experimente o melhor treinamento, desfrute da infraestrutura de excelência, junte-se ao time de elite e, é claro, integre-se a uma comunidade vibrante, formada por pessoas extraordinárias.</p>
                        </div>
                    </div>
                </div>
                <div className="content-shape-bottom">
                    <img src={shape_bottom} />
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 text-center mt-5">
                        <Link to="/franquia" className="btn-border">Quero ter minha própria BRUK</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}