import React,  { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaInstagram } from "react-icons/fa";

import api from '../services/api';

import fokus from '../assets/images/fokus.png';
import powerade from '../assets/images/powerade.png';
import ink from '../assets/images/ink.png';
import shape_top from '../assets/images/shape-top.png';
import shape_bottom from '../assets/images/shape-bottom.png';

export default function ContentBranch() {

    const [loading, setLoading] = useState(true);
    const [id, setId] = useState('');
    const [branch, setBranch] = useState([]);

    async function loadPage(){
        try{
            const response = await api.post('/branch');
            const groupedBranch = chunkArray(response.data.data.branch, 3);
            setBranch(groupedBranch);
            setLoading(false);
        }catch(err){
            setLoading(false);
        }
    }

    function chunkArray(array, chunkSize) {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    }

    useEffect(() => {
        loadPage();
    }, []);

    return (
        <div className="content-branch">
            <h2 data-aos="fade-up" data-aos-delay="50">Encontre a BRUK</h2>
            <h3 data-aos="fade-up" data-aos-delay="100">Mais próxima de você</h3>
            <div className="container-branch">
                <div className="container">
                    <div className="row">
                        {branch.map((group, indexGroup) => (
                            <div class="col-md-12" key={indexGroup}>
                                <div className="row">
                                {group.map((item, index) => (
                                    <div class="col-md-4 m-auto" key={index} data-aos="fade-up" data-aos-delay={`${(index + 1) * 100}`}>
                                        <div className="branch-box">
                                            <h4>{item.name}</h4>
                                            <p>{item.address}, {item.number} {item.complement}<br />{item.neighborhood},<br />{item.city} - {item.state}</p>
                                            <div className="branch-link"><a href={item.instagram} target="_blank" className="branch-icon"><FaInstagram /></a></div>
                                        </div>
                                    </div>
                                ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="content-section section-7">
                <div className="content-shape-top">
                    <img src={shape_top} />
                </div>
                <div className="row">
                    <div className="col-md-12 m-auto pb-4">
                        <h2 className="title-blk-green" data-aos="fade-up">PIPELINE <span>BRUK</span></h2>
                        <p className="fs-soon">Em breve novas UNIDADES!</p>
                        <div className="pipeline-content mt-4 pt-4">
                            <h3 data-aos="fade-up" data-aos-delay="50">BRASIL</h3>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 m-auto pb-4">
                                        <h4 className="mb-3" data-aos="fade-up" data-aos-delay="150">RJ</h4>
                                        <div className="pipeline-container">
                                            <div className="pipeline-box" data-aos="fade-up" data-aos-delay="200">1</div>
                                            <div className="pipeline-box" data-aos="fade-up" data-aos-delay="250">2</div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 m-auto pb-4">
                                        <h4 className="mb-3" data-aos="fade-up" data-aos-delay="300">SP</h4>
                                        <div className="pipeline-container">
                                            <div className="pipeline-box" data-aos="fade-up" data-aos-delay="350">1</div>
                                            <div className="pipeline-box" data-aos="fade-up" data-aos-delay="400">2</div>
                                            <div className="pipeline-box" data-aos="fade-up" data-aos-delay="450">3</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pipeline-content mt-4 pt-5">
                            <h3 data-aos="fade-up" data-aos-delay="550">EXTERIOR</h3>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 d-flex justify-content-center gap-5 m-auto pb-4">
                                        <div className="pipeline-exterior">
                                            <h4 className="mb-3" data-aos="fade-up" data-aos-delay="600">EUA</h4>
                                            <div className="pipeline-container">
                                                <div className="pipeline-box" data-aos="fade-up" data-aos-delay="650">1</div>
                                            </div>
                                        </div>
                                        <div className="pipeline-exterior">
                                            <h4 className="mb-3" data-aos="fade-up" data-aos-delay="700">PORTUGAL</h4>
                                            <div className="pipeline-container">
                                                <div className="pipeline-box" data-aos="fade-up" data-aos-delay="750">1</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-shape-bottom">
                    <img src={shape_bottom} />
                </div>
            </div>
            <div className="container-partners">
                <div className="container">
                    <div className="row">
                        <div class="col-md-4 m-auto"data-aos="fade-up" data-aos-delay="50">
                            <div className="partner-box">
                                <img src={fokus} />
                            </div>
                        </div>
                        <div class="col-md-4 m-auto"data-aos="fade-up" data-aos-delay="150">
                            <div className="partner-box">
                                <img src={powerade} />
                            </div>
                        </div>
                        <div class="col-md-4 m-auto"data-aos="fade-up" data-aos-delay="200">
                            <div className="partner-box">
                                <img src={ink} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}